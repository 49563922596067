import Bowser from 'bowser';

const getMajor = (version: string) => Number.parseInt(version);

export const isBrowserFeasible = (userAgentStr: string) => {
  //https://en.wikipedia.org/wiki/Comparison_of_browser_engines
  const browser = Bowser.parse(userAgentStr || '');
  //we disable old browsers, like IE , see: https://en.wikipedia.org/wiki/Browser_engine & https://www.w3schools.com/js/js_versions.asp
  const oldEngines = [
    'Trident',
    'EdgeHTML',
    'Presto',
    'Mariner',
    'Tasman',
    'Tkhtml',
  ];
  if (oldEngines.includes(browser.engine.name)) return false;
  const major = getMajor(browser.browser.version);

  if (browser.engine === 'Blink' /* chrome & chromium*/ && major < 51)
    return false;
  if (
    browser.browser.name === 'Chrome' &&
    browser.engine.name === 'Webkit' /* old chrome */
  )
    return false;
  if (browser.browser.name === 'Safari' && major < 11) return false;
  if (browser.browser.name === 'Opera' && major < 38) return false;
  if (browser.browser.name === 'Firefox' && major < 52) return false;
  return true;
};
