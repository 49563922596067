import { ApolloProvider } from '@apollo/react-common';
import { Loading } from '@hp/atomic';
import { config, Language } from '@hp/config';
import { withContext } from '@hp/context';
import { loadCatalog } from '@hp/locale';
import { RouteProps, routes, RoutesType, useHistory, useRouter } from '@hp/seo';
import { GlobalStyles } from '@hp/theme';
import { cookies, gtmPageView, StorageKeys } from '@hp/utils';
import { Catalog } from '@lingui/core';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import App, { AppProps } from 'next/app';
import { AppContextType } from 'next/dist/next-server/lib/utils';
import Head from 'next/head';
import { Router } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Normalize } from 'styled-normalize';

import { isBrowserFeasible } from '../lib/isBrowserFeasible';
import withApollo from '../lib/withApollo';
import { LanguageProvider } from '../providers/LanguageProvider';
import LinguiProvider from '../providers/LinguiProvider';
import { SystemModalProvider } from '../providers/SystemModal';

type Props = {
  language: Language;
  catalog: Catalog;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  authorized: boolean;
  catalogFullLoaded: boolean;
} & AppProps;

declare global {
  interface Window {
    dpdServerTime: number;
    dpdTzOffsetDelta: number;
  }
}

const MyApp = ({
  Component,
  pageProps,
  language: languageProps,
  catalog: catalogProps,
  catalogFullLoaded: catalogFullLoadedProps,
  apolloClient,
  router,
}: Props) => {
  const nextRouter = useRouter();

  const isCurrentRoute = ({ href }: RouteProps) => {
    if (typeof href === 'function') {
      const finalHref = href(router.query);

      return finalHref === router.asPath;
    }

    return href === router.route;
  };

  const currentRouteEntry = Object.entries(routes).find(([_, value]) =>
    isCurrentRoute(value),
  ) as [keyof RoutesType | undefined, RouteProps | undefined];

  const currentRoute = currentRouteEntry?.[0];
  const currentRouteProps = currentRouteEntry?.[1];
  const { requirements, ...restRouteProps } = currentRouteProps ?? {};

  useEffect(() => {
    const scrollToTop = () => window.scrollTo(0, 0);
    Router.events.on('routeChangeComplete', scrollToTop);

    return () => {
      Router.events.off('routeChangeComplete', scrollToTop);
    };
  });

  // Initiate GTM
  useEffect(() => {
    const handleRouteChange = (url: string) => gtmPageView(url);
    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  const [data, setData] = useState({
    catalog: catalogProps,
    catalogFullLoaded: catalogFullLoadedProps,
    language: languageProps,
    canRenderPage: !currentRouteProps?.noSsr,
  });

  const history = useHistory();

  const { language, catalogFullLoaded, catalog, canRenderPage } = data;
  const ssr = typeof window === 'undefined';

  const isUserStuffRequired = !ssr;

  const fallbackRoute =
    (!ssr || (ssr && !currentRouteProps?.noSsr)) &&
    (!isUserStuffRequired || isUserStuffRequired) &&
    requirements?.({
      language,
      ssr,
      //@ts-ignore  if requirements exists, then restRouteProps is not "{}""
      routeProps: restRouteProps,
      query: router.query,
    });
  if (fallbackRoute && !ssr) {
    nextRouter.push(fallbackRoute);
  }

  const changeLanguage = (lang: Language) => {
    setData((prev) => ({
      ...prev,
      catalogFullLoaded: false,
      language: lang,
    }));

    cookies.set(null, StorageKeys.language, lang);
  };

  useEffect(() => {
    if (canRenderPage && catalogFullLoaded) return;
    if (isUserStuffRequired) {
      if (!catalogFullLoaded) {
        //catalog was not loaded, loading...
        loadCatalog(language).then((newCatalog) => {
          setData((prev) => ({
            ...prev,
            catalog: newCatalog,
            catalogFullLoaded: true,
            canRenderPage: true,
          }));
        });
      } else {
        //catalog is not required
        setData((prev) => ({
          ...prev,
          canRenderPage: true,
        }));
      }
    } else {
      //user is not required, load catalog if necessary
      if (!catalogFullLoaded) {
        loadCatalog(language).then((newCatalog) => {
          setData((prev) => ({
            ...prev,
            catalog: newCatalog,
            catalogFullLoaded: true,
            canRenderPage: true,
          }));
        });
      }
    }
  }, [language, catalogFullLoaded, isUserStuffRequired, canRenderPage]);

  useEffect(() => {
    if (!canRenderPage || !currentRoute) return;
    history.push(currentRoute);
  }, [canRenderPage, currentRoute]);

  return (
    <>
      <Head>
        <meta
          content="width=device-width, initial-scale=1, maximum-scale=5, shrink-to-fit=no"
          name="viewport"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      {canRenderPage && !fallbackRoute ? (
        <LanguageProvider language={language} setLanguage={changeLanguage}>
          <ApolloProvider client={apolloClient}>
            <LinguiProvider
              catalogs={{ [language]: catalog }}
              language={language}
              loading={!catalogFullLoaded}
            >
              <Normalize />
              <GlobalStyles />
              <SystemModalProvider>
                <Component {...pageProps} />
              </SystemModalProvider>
            </LinguiProvider>
          </ApolloProvider>
        </LanguageProvider>
      ) : (
        <Loading />
      )}
    </>
  );
};

const filterNonSerializedMessages = (messages: Catalog['messages']) => {
  const result = {};
  const serializedEntries = Object.entries(messages).filter(
    ([_key, value]) => typeof value === 'string',
  );
  serializedEntries.forEach(([key, value]) => (result[key] = value));

  return result;
};

MyApp.getInitialProps = async (appContext: AppContextType<Router>) => {
  const appProps = await App.getInitialProps(appContext);
  const { req, res, query } = appContext.ctx;
  const ssr = typeof window === 'undefined';
  const language =
    cookies.get(appContext.ctx, StorageKeys.language) ||
    config.app.defaultLanguage;

  if (ssr && !isBrowserFeasible(req.headers['user-agent'])) {
    if (!req.url.includes('browser-not-supported.html')) {
      //@ts-ignore
      res.redirect(`/browser-not-supported.html`);
      res.end();

      return {};
    }
  }

  let catalog: Catalog = await loadCatalog(language);
  if (ssr) {
    //when SSR, catalog does not persist all props (e.g. function can' be serailized) ;
    //we use all, what we can, for example for proper rendering headers
    catalog = {
      messages: filterNonSerializedMessages(catalog.messages),
    };
  }

  return {
    ...appProps,
    query,
    language,
    catalog,
    catalogFullLoaded: !ssr,
  };
};

export default withContext(withApollo(MyApp));
