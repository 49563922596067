import { Language } from '@hp/config';
import { I18nFuncContextProvider } from '@hp/locale';
import { Catalogs } from '@lingui/core';
import { I18n, I18nProvider } from '@lingui/react';
import React, { FC } from 'react';

type LinguiProviderProps = {
  language: Language;
  catalogs: Catalogs;
  loading?: boolean;
};

const LinguiProvider: FC<LinguiProviderProps> = ({
  catalogs,
  loading,
  language,
  children,
}) => {
  return (
    <I18nProvider
      catalogs={catalogs}
      language={language.toLowerCase()}
      //when catalog is still loading, we rather show empty string than ID
      missing={(_lang, id) => (loading ? ' ' : id)}
    >
      <I18n>
        {({ i18n }) => (
          <I18nFuncContextProvider value={i18n}>
            {children}
          </I18nFuncContextProvider>
        )}
      </I18n>
    </I18nProvider>
  );
};

export default LinguiProvider;
