import { AppContextType } from 'next/dist/next-server/lib/utils';
import React, { Component } from 'react';

export const withContext = (ComposedComponent) => {
  return class WithGraphData extends Component {
    static async getInitialProps(appContext: AppContextType) {
      // Evaluate the composed component's getInitialProps()
      let composedInitialProps = {};
      if (ComposedComponent.getInitialProps) {
        composedInitialProps = await ComposedComponent.getInitialProps({
          ...appContext,
        });
      }

      return {
        ...composedInitialProps,
      };
    }

    constructor(props) {
      super(props);
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  };
};
